import React from 'react'
import Header from '../Header/Header';
import "./Hero.css";
import hero_image from "../../Assets/hero_image.png";
import hero_image_back from "../../Assets/hero_image_back.png";
import heart from "../../Assets/heart.png";
import calories from "../../Assets/calories.png"
import {motion} from "../../../node_modules/framer-motion/"
import Counter from 'number-counter'
import { Link } from 'react-scroll';

const Hero = () => {
  const transition = {type : 'spring', duration : 3}
  const mobile= window.innerWidth<=768?true:false;
  return (
    <div className='hero'>
    <div className='blur hero-blur'></div>
    <div className='left-h'>
      <Header/>
      
{/* the best ad */}
      <div className='the-best-ad'>
      <motion.div 
        initial = {{left:mobile? '176px':'238px'}}
        whileInView = {{left: '8px'}}
        transition={{...transition,type : 'tween'}}
        ></motion.div>
        <span>The best fitness club is the town</span>
      </div>

      {/* Hero Heading */}
      <div className='hero-text'>
        <div>
        <span className='stroke-text'>Shape </span>
        <span>Your</span>
        </div>
        <div>
            <span>Ideal Body</span>
        </div>
        <div className='span'>
            <span>In here we will help you to shape and build your ideal body and live up your life to fullest</span>
        </div>
      </div>

      {/* Figures */}
      <div className='figures'>
        <div>
        <span>
        <Counter start={0} end={140} delay="2" preFix="+" />
        </span>
        <span>EXPERT COACHES</span>
        </div>
        <div>
        <span>
        <Counter start={0} end={978} delay="1" preFix="+" />
        </span>
        <span>MEMBERS JOINED</span>
        </div>
        <div>
        <span>
        <Counter start={0} end={50} delay="2" preFix="+" />
        </span>
        <span>FITNESS PROGRAMS</span>
        </div>
      </div>

      {/* Hero Buttons */}
      <div className='hero-button'>
        <butotn className="btn"><Link to="Join" spy={true} smooth={true}>Get Started</Link></butotn>
        <butotn className="btn"><Link to="Join" spy={true} smooth={true}>Learn More</Link></butotn>
      </div>
    </div>
    <div className='right-h'>
       <button className='btn'><Link to="Join" spy={true} smooth={true} style={{color:'black'}}>Join Now</Link></button>


       <motion.div className='heart-rate'
        initial={{right:mobile? '-7rem':'1rem'}}
        whileInView={{right:mobile? '-1rem':'4rem'}}
        transition={transition}
       >
        <img src={heart} alt=""/> 
        <span>Heart Rate</span>
        <span>116 bpm</span>
       </motion.div>

       {/* hero images */}
       <img src={hero_image} alt="" className='hero-image'/>
       <motion.img 
       initial={{right:'11rem'}}
       whileInView={{right:'20rem'}}
       transition={transition}
       src={hero_image_back} alt="" className='hero-image-back'/>

       {/* calories */}
       <motion.div 
       initial={{right:mobile? '10rem':'37rem'}}
       whileInView={{right:mobile? '-1rem':'28rem'}}
       transition={transition}
       className='calories'>
       <img src={calories} alt=""/>
       <div>
       <span>Calories Burned</span>
       <span>220 kcal</span>
       </div>
       </motion.div>
    </div>
    </div>
  );
}

export default Hero