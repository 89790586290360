import React, { useState } from 'react';
import "./Header.css";
import logo from '../../Assets/logo.png';
import {Link} from "react-scroll"
const Header = () => {
  const mobile= window.innerWidth<=768?true:false;
  const [menuopened,setMenuopened] = useState(false);

  return (
      <div className='Header' id='Home'>
    <img src={logo} alt="" className='logo' />
    {menuopened === false && mobile === true ? (
      <div className='burger-menu' onClick={()=>setMenuopened(true)}>
       <div className='burger_class'></div>
       <div className='burger_class'></div>
       <div className='burger_class'></div>
      </div>
    ) : (
      <ul className='header-menu'>
        <li >
        <Link
        id='hu'
        onClick={()=>setMenuopened(false)}
        activeClass='active'
        to="Home"
        spy={true}
        smooth={true}
        >
        Home
        </Link>
        </li>
        <li>
        <Link
        id='hu'
        onClick={()=>setMenuopened(false)}
        to="Programs"
        spy={true}
        smooth={true}>
        Programs
        </Link>
        </li>
        <li>
        <Link
        id='hu'
        onClick={()=>setMenuopened(false)}
        to="Reasons"
        spy={true}
        smooth={true}>
        Why Us
        </Link></li>
        <li >
         <Link
         id='hu'
        onClick={()=>setMenuopened(false)}
        to="Plans"
        spy={true}
        smooth={true}>
        Plans
        </Link>
        </li>
        <li >
         <Link
         id='hu'
        onClick={()=>setMenuopened(false)}
        to="Testimonials"
        spy={true}
        smooth={true}>
        Testimonials
        </Link>
        </li>
    </ul>
    )}
      
    </div>
  )
}

export default Header