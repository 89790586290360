import React from 'react'
import "./Reasons.css"
import image1 from "../../Assets/image1.png"
import image2 from "../../Assets/image2.png"
import image3 from "../../Assets/image3.png"
import image4 from "../../Assets/image4.png"
import nb from "../../Assets/nb.png"
import adidas from "../../Assets/Adidas.png"
import nike from "../../Assets/nike.png"
import tick from "../../Assets/tick.png"
const Reasons = () => {
  return (
    <div className='reasons' id='Reasons'>
    <div className='left-r'>
        <img src={image1} alt=""/>
        <img src={image2} alt=""/>
        <img src={image3} alt=""/>
        <img src={image4} alt=""/>
    </div>
    <div className='right-r'>
    <span>Some reasons</span>

    <div>
        <span className='stroke-text'>Why</span>
        <span> Choose us?</span>
    </div>

    <div className='details-r'>
        <div>
            <img src={tick} alt=""/>
            <span>OVER 140+ EXPERT COACHS</span>
        </div>
        <div>
            <img src={tick} alt=""/>
            <span>TRAIN SMARTER AND FASTER THAN BEFORE</span>
        </div>
        <div>
            <img src={tick} alt=""/>
            <span>1 FREE PROGRAM FOR NEW MEMBER</span>
        </div>
        <div>
            <img src={tick} alt=""/>
            <span>RELIABLE PARTNERS</span>
        </div>
    </div>
<span style={{color: "var(--gray)",fontWeight:"normal"}}>OUR PARTNERS</span>
<div className='partners'>
    <img src={nb} alt="" />
    <img src={adidas} alt="" />
    <img src={nike} alt="" />
</div>
    </div>
    </div>
  )
}

export default Reasons