import React from 'react'
import "./Program.css"
import {programsData} from "../../Data/ProgramsData"
import RightArrow from "../../Assets/rightArrow.png"
import {Link} from "react-scroll"
const Program = () => {
  return (
    <div className='programs'>
      {/*Header  */}
      <div className='program-header' id='Programs'>
        <span className='stroke-text'>Explore Our</span>
        <span>Programs</span>
        <span className='stroke-text'>To shape you</span>
      </div>

      <div className='program-categories'>
        {programsData.map((program)=>(
          <div className='category'>
            {program.image}
            <span>{program.heading}</span>
            <span>{program.details}</span>
            <div><Link className='join-now' to="Join" spy={true} smooth={true}><span>Join Now</span><img src={RightArrow} alt=""/></Link></div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Program