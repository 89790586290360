import React from 'react'
import "./Footer.css"
import Github from "../../Assets/github.png"
import Instagram from "../../Assets/instagram.png"
import Linkedin from "../../Assets/linkedin.png"
import Logo from "../../Assets/logo.png"
const Footer = () => {
  return (
    <div className='footer-container'>
    <hr/>
    <div className='footer'>
    <div className='social-link'>
        <img src={Github} alt=''/>
        <img src={Instagram} alt=''/>
        <img src={Linkedin} alt=''/>
    </div>
    <div className='logo-f'>
        <img src={Logo} alt=""/>
    </div>
    </div>
    <div className='blur blur-f-1'></div>
    <div className='blur blur-f-2'></div>
    </div>
  )
}

export default Footer
